<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('GorevPlani.Gorev_Plani') }}
        <download-excel
          :data="GorevPlani"
          :name="$t('GorevPlani.ExcelFile')"
          :worksheet="$t('GorevPlani.ExcelFile')"
          :fields="ExcelFields"
          :header="ExcelHeader"
          class="ml-3"
        >
          <img
            src="@/assets/images/misc/excel.png"
            style="width: 25px"
          />
        </download-excel>
      </v-card-title>
    </v-card>
    <v-data-table
      :headers="sutunlar"
      :items="GorevPlani"
      :search="arama"
    >
      <template v-slot:item.islem="{ item }">
        <v-btn
          text
          icon
          color="primary"
          @click="edit(item)"
        >
          <v-icon>
            {{ icons.mdiPencil }}
          </v-icon>
        </v-btn>
        <v-btn
          text
          icon
          color="primary"
          @click="remove(item)"
        >
          <v-icon>
            {{ icons.mdiTrashCanOutline }}
          </v-icon>
        </v-btn>
        <!--<v-btn
          text
          icon
          color="primary"
          @click="editSchedule(item)"
        >
          <v-icon>
            {{ icons.mdiAccountDetails }}
          </v-icon>
        </v-btn>-->
        <v-btn
          text
          icon
          color="primary"
          @click="editAutomaticSchedule(item)"
        >
          <v-icon>
            {{ icons.mdiAccountGroup }}
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.personel_sayisi="{ item }">
        <v-badge
          color="primary"
          :content="item.personel_sayisi"
          offset-x="10"
          offset-y="18"
        >
          <v-avatar size="30">
            <img
              src="/img/person.png"
              :alt="item.personel_sayisi"
            >
          </v-avatar>
        </v-badge>
      </template>
    </v-data-table>
    <silme-onayi
      :sil="sil"
      :title="evraktipi"
      @Sil="GorevPlaniSil"
    />
    <GorevPlaniGuncelle
      v-if="duzenle"
      :editdata="EditItem"
      @close="duzenle=false"
    />
    <GorevPlaniPersonelGuncelle
      v-if="duzenlePersonel"
      :editdata="EditItem"
      @close="duzenlePersonel=false"
    />
    <GorevPlaniOlustur @setReload='setReload=$event' />
    <GorevPlaniPersonelOtomatikGuncelle
      v-if="duzenle2"
      :editdata="EditItem"
      :show-modal="duzenle2"
      @showModal="duzenle2=$event"
      @setReload='setReload=$event'
    />
  </div>
</template>

<script>
import {
  mdiPencil, mdiTrashCanOutline, mdiAccountDetails, mdiAccountGroup,
} from '@mdi/js'
import SilmeOnayi from '@/components/silme-onayi'
import GorevPlaniGuncelle from '@/views/gorev-plani/gorev-plani-guncelle'
import GorevPlaniOlustur from '@/views/gorev-plani/gorev-plani-olustur'
import GorevPlaniPersonelGuncelle from '@/views/gorev-plani/gorev-plani-personel-guncelle'
import GorevPlaniPersonelOtomatikGuncelle from '@/views/gorev-plani/gorev-plan-personel-otomatik-guncelle'
import api from '@/boot/axios'

export default {
  name: 'GorevPlani',
  components: {
    GorevPlaniOlustur,
    GorevPlaniGuncelle,
    GorevPlaniPersonelGuncelle,
    SilmeOnayi,
    GorevPlaniPersonelOtomatikGuncelle,
  },
  data() {
    return {
      setReload: false,
      icons: {
        mdiPencil,
        mdiTrashCanOutline,
        mdiAccountDetails,
        mdiAccountGroup,
      },
      sutunlar: [
        {
          align: 'start',
          text: this.$t('GorevPlani.islem'),
          value: 'islem',
        },
        {
          text: this.$t('GorevPlani.personel_sayisi'),
          align: 'start',
          value: 'personel_sayisi',
        },
        {
          text: this.$t('GorevPlani.firma'),
          value: 'firma_adi',
        },
        {
          text: this.$t('GorevPlani.musteri_adi'),
          value: 'musteri_adi',
        },

        {
          text: this.$t('GorevPlani.musteri_kod'),
          value: 'musteri_kod',
        },
        {
          text: this.$t('GorevPlani.cadde_nr'),
          value: 'cadde_nr',
        },
        {
          text: this.$t('GorevPlani.postakodu_sehir'),
          value: 'postakodu_sehir',
        },
        {
          text: this.$t('GorevPlani.ulke'),
          value: 'ulke',
        },
        {
          text: this.$t('GorevPlani.baslama_tarihi'),
          value: 'baslama_tarihi',
        },
        {
          text: this.$t('GorevPlani.baslama_saati'),
          value: 'baslama_saati',
        },
        {
          text: this.$t('GorevPlani.bitis_tarihi'),
          value: 'bitis_tarihi',
        },
        {
          text: this.$t('GorevPlani.bitis_saati'),
          value: 'bitis_saati',
        },
        {
          text: this.$t('GorevPlani.onay'),
          value: 'onay',
        },
      ],
      duzenle: false,
      duzenlePersonel: false,
      duzenle2: false,
      EditItem: {},
      evraktipi: null,
      sil: false,
      silItem: null,

      GorevPlani: [],
    }
  },
  computed: {
    PersonellerListesi() {
      return this.$store.state.Personeller.Personeller
    },

    /* /GorevPlani() {
      return this.$store.state.GorevPlani.GorevPlani
    },/ */
    GorevPlaniPersonelDetaylari() {
      return this.$store.state.GorevPlani.GorevPlaniPersonelDetay
    },
    arama() {
      return this.$store.state.arama
    },
    ExcelFields() {
      let veri
      const fields = { }
      if (this.GorevPlani.length > 0) {
        veri = Object.keys(this.GorevPlani[0])
        veri.forEach(item => {
          // eslint-disable-next-line no-unused-vars
          const itemKey = this.$t(`GorevPlani.${item}`)
          fields[itemKey] = item
        })
      }

      return fields
    },
    MusteriAdresleri() {
      return this.$store.state.Musteriler.MusteriAdresleri
    },
    ExcelHeader() {
      return [this.$t('APP_NAME'), `${this.$t('tarih')}:${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`]
    },
  },
  mounted() {
    // GorevPlani Listesi Çek (store/GorevPlani.js)
    this.$store.commit('GorevPlani/setManuelGuncelle', 0)
    this.getCurrentGorevPlanlari()
  },
  methods: {
    async getCurrentGorevPlanlari() {
      const { data } = await api.get('gorevplani', { params: { manuel_guncelle: 0 } }).catch(error => {
        if (error.response.status === 500) {
          this.GorevPlaniData = []
        }
      })
      if (data?.code === 200) {
        this.GorevPlani = data.result
      }
      this.$store.dispatch('Action', { name: 'Personeller/PersonellerListesi' })
    },
    // Sil Evet Hayır sonucu
    GorevPlaniSil(bool) {
      const { islem_kodu } = this.silItem
      this.sil = false
      if (bool) { // Evet tıklandı
        this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniSil', data: { id: islem_kodu } })
      }
    },

    // Sil Popup Açmak
    remove(item) {
      this.silItem = item
      this.evraktipi = item.isim
      this.sil = true
    },
    edit(item) {
      this.EditItem = item
      this.duzenle = true
    },
    kullaniciAdiGetir(id) {
      // eslint-disable-next-line eqeqeq
      const personel = this.PersonellerListesi.find(f => f.id == id)
      if (personel != undefined) {
        return `${personel.isim} ${personel.soyisim}`
      }
    },
    editAutomaticSchedule(item) {
      const data = { id: item.musteri_id }
      const item2 = JSON.parse(JSON.stringify(item))
      this.$store.dispatch('Action', { name: 'Musteriler/MusteriAdresleriTumu', data }).then(() => {
        // eslint-disable-next-line no-param-reassign
        if (this.MusteriAdresleri.length == 1) {
          // eslint-disable-next-line no-param-reassign
          item2.adres_id = this.MusteriAdresleri[0].id
        }
        item2.musteri_adresleri = this.MusteriAdresleri
        this.EditItem = item2
        this.duzenle2 = true
        console.log(item2)
      })
    },
    async editSchedule(item) {
      const d = { ...item }
      await this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniDetayliGetir', data: d })

      // eslint-disable-next-line no-unused-vars

      const mapped = this.GorevPlaniPersonelDetaylari.items.map(a => ({
        ...a.map(f => ({
          ...f,
          bitis_tarihi: d.bitis_tarihi,
        })),
      }))
      mapped.sort((a, b) => a.calisma_tarihi - b.calisma_tarihi)
      const postData = {
        from: this.GorevPlaniPersonelDetaylari.from,
        to: this.GorevPlaniPersonelDetaylari.to,
        items: mapped,
        headers: this.GorevPlaniPersonelDetaylari.headers,
      }

      this.EditItem = postData

      console.log('EdıtItem:', this.EditItem)

      this.duzenlePersonel = true
    },
  },
  watch: {
    setReload: {
      handler() {
        if (this.setReload) {
          this.getCurrentGorevPlanlari()
          this.setReload = false
        }
      },
    },
  },
}
</script>

<style scoped>

</style>
